import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useAuth } from '../../hooks/useAuth';

const Notifications = () => {
  const { user } = useAuth();
  const [notifications, setNotifications] = useState([]);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const fetchNotifications = async () => {
      if (user) {
        try {
          console.log('User object:', user);
          const userId = user.userType === 'therapist' ? user._id : user.uid;
          if (!userId) {
            console.error('User ID is undefined');
            return;
          }
          const url = user.userType === 'therapist'
            ? `${process.env.REACT_APP_API_BASE_URL}/api/notifications/therapist/${userId}`
            : `${process.env.REACT_APP_API_BASE_URL}/api/notifications/${userId}`;
          console.log(`Fetching notifications for user ID: ${userId}`);
          const response = await axios.get(url);
          console.log('Fetched notifications:', response.data);
          setNotifications(response.data);
        } catch (error) {
          console.error('Error fetching notifications:', error);
        }
      } else {
        console.error('User object is undefined');
      }
    };

    fetchNotifications();
  }, [user]);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const handleRemoveNotification = async (notificationId) => {
    try {
      await axios.delete(`${process.env.REACT_APP_API_BASE_URL}/api/notifications/${notificationId}`);
      setNotifications(notifications.filter(notification => notification._id !== notificationId));
    } catch (error) {
      console.error('Error removing notification:', error);
    }
  };

  return (
    <div style={{ position: 'fixed', top: 20, right: 20, cursor: 'pointer', zIndex: 1001 }}>
      <div onClick={toggleModal} style={{ display: 'flex', alignItems: 'center' }}>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style={{ fill: '#5738a6' }}>
          <path d="M19 13.586V10c0-3.217-2.185-5.927-5.145-6.742C13.562 2.52 12.846 2 12 2s-1.562.52-1.855 1.258C7.185 4.074 5 6.783 5 10v3.586l-1.707 1.707A.996.996 0 0 0 3 16v2a1 1 0 0 0 1 1h16a1 1 0 0 0 1-1v-2a.996.996 0 0 0-.293-.707L19 13.586zM19 17H5v-.586l1.707-1.707A.996.996 0 0 0 7 14v-4c0-2.757 2.243-5 5-5s5 2.243 5 5v4c0 .266.105.52.293.707L19 16.414V17zm-7 5a2.98 2.98 0 0 0 2.818-2H9.182A2.98 2.98 0 0 0 12 22z"></path>
        </svg>
      </div>
      {showModal && (
        <div style={{
          position: 'absolute',
          top: '30px',
          right: '0',
          background: '#fff',
          padding: '10px',
          borderRadius: '5px',
          boxShadow: '0 0 10px rgba(0,0,0,0.1)',
          zIndex: 1001,
          width: '300px'
        }}>
          {notifications.length > 0 ? (
            notifications.map((notification, index) => (
              <div key={index} style={{
                border: '1px solid #5738a6',
                borderRadius: '5px',
                padding: '10px',
                marginTop: index !== 0 ? '10px' : '0',
                color: '#333',  // Cinza escuro
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}>
                <span>{notification.message}</span>
                <svg
                  onClick={() => handleRemoveNotification(notification._id)}
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  style={{ fill: '#5738a6', cursor: 'pointer' }}
                >
                  <path d="M20 2H4c-1.103 0-2 .897-2 2v12c0 1.103.897 2 2 2h3v3.767L13.277 18H20c1.103 0 2-.897 2-2V4c0-1.103-.897-2-2-2zm0 14h-7.277L9 18.233V16H4V4h16v12z"></path>
                  <path d="m17.207 7.207-1.414-1.414L11 10.586 8.707 8.293 7.293 9.707 11 13.414z"></path>
                </svg>
              </div>
            ))
          ) : (
            <div>No new notifications</div>
          )}
        </div>
      )}
    </div>
  );
};

export default Notifications;
