import React, { useEffect, useState } from 'react';
import axios from 'axios';
import TherapistCard from '../components/Therapists/TherapistCard';
import ScheduleTherapistPage from '../components/schedule/ScheduleTherapistPage';
import SearchMenu from '../components/SearchMenu';
import './HallPage.css';

const HallPage = () => {
  const [therapists, setTherapists] = useState([]);
  const [filteredTherapists, setFilteredTherapists] = useState([]);
  const [filters, setFilters] = useState({
    search: '',
    category: '',
    language: '',
    specialistType: ''
  });
  const [selectedTherapistId, setSelectedTherapistId] = useState(null);

  useEffect(() => {
    const fetchTherapists = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/therapists`);
        setTherapists(response.data);
        setFilteredTherapists(response.data);
      } catch (error) {
        console.error('Error fetching therapists:', error);
      }
    };

    fetchTherapists();
  }, []);

  useEffect(() => {
    document.body.classList.add('hall-page-body');
    return () => {
      document.body.classList.remove('hall-page-body');
    };
  }, []);

  useEffect(() => {
    filterTherapists();
  }, [filters]);

  const handleSearchChange = (e) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      search: e.target.value
    }));
  };

  const handleCategoryChange = (e) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      category: e.target.value
    }));
  };

  const handleLanguageChange = (e) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      language: e.target.value
    }));
  };

  const handleSpecialistTypeChange = (e) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      specialistType: e.target.value
    }));
  };

  const filterTherapists = () => {
    let filtered = therapists;

    if (filters.search) {
      filtered = filtered.filter(therapist =>
        therapist.name.toLowerCase().includes(filters.search.toLowerCase()) ||
        therapist.description.toLowerCase().includes(filters.search.toLowerCase()) ||
        therapist.areasOfExpertise.some(area =>
          area.toLowerCase().includes(filters.search.toLowerCase())
        )
      );
    }

    if (filters.category) {
      filtered = filtered.filter(therapist =>
        therapist.areasOfExpertise.some(area =>
          area.includes(filters.category)
        )
      );
    }

    if (filters.language) {
      filtered = filtered.filter(therapist =>
        therapist.languages.includes(filters.language)
      );
    }

    if (filters.specialistType) {
      filtered = filtered.filter(therapist =>
        therapist.specialistTypes.includes(filters.specialistType)
      );
    }

    setFilteredTherapists(filtered);
  };

  const handleTherapistClick = (id) => {
    setSelectedTherapistId(id);
  };

  const handleSeeAllTherapists = () => {
    setSelectedTherapistId(null);
  };

  return (
    <div className="hall-page">
      {selectedTherapistId ? (
        <ScheduleTherapistPage 
          therapistId={selectedTherapistId}
          onBack={handleSeeAllTherapists}
        />
      ) : (
        <>
          <SearchMenu 
            filters={filters}
            onSearchChange={handleSearchChange}
            onCategoryChange={handleCategoryChange}
            onLanguageChange={handleLanguageChange}
            onSpecialistTypeChange={handleSpecialistTypeChange}
          />
          <div className="therapist-cards-container">
            {filteredTherapists.map((therapist) => (
              <TherapistCard
                key={therapist._id}
                id={therapist._id}
                name={therapist.name}
                photo={therapist.profilePhoto}
                expertise={therapist.areasOfExpertise}
                description={therapist.description}
                price={`U$ ${therapist.consultationFee}`}
                rating={therapist.rating}
                sessions={therapist.sessions || 0}
                languages={therapist.languages}
                onClick={() => handleTherapistClick(therapist._id)}
              />
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default HallPage;
