import React from 'react';

const TherapistRegistrationStep5 = ({ formData, handleCheckboxChange }) => {
  const languages = ['Inglês', 'Francês', 'Alemão', 'Italiano', 'Português'];
  const specialistTypes = ['Terapeuta', 'Coach', 'Nutricionista', 'Psicanalista', 'Psicólogo', 'Psiquiatra'];

  return (
    <div className="registration-step">
      <h2>Language</h2>
      {languages.map((language) => (
        <div key={language} className="checkbox-container">
          <input
            type="checkbox"
            value={language}
            checked={formData.languages.includes(language)}
            onChange={(e) => handleCheckboxChange(e, 'languages')}
            className="checkbox-input"
          />
          <label className="checkbox-label">{language}</label>
        </div>
      ))}

      <h2>Specialist Type</h2>
      {specialistTypes.map((type) => (
        <div key={type} className="checkbox-container">
          <input
            type="checkbox"
            value={type}
            checked={formData.specialistTypes.includes(type)}
            onChange={(e) => handleCheckboxChange(e, 'specialistTypes')}
            className="checkbox-input"
          />
          <label className="checkbox-label">{type}</label>
        </div>
      ))}
    </div>
  );
};

export default TherapistRegistrationStep5;
