import React, { useEffect, useState } from 'react';
import axios from 'axios';
import ShowProfileTherapistBU from '../perfil/ShowProfileTherapistBU';

const ScheduleTherapistPage = ({ therapistId, onBack }) => {
  const [therapistData, setTherapistData] = useState(null);

  useEffect(() => {
    const fetchTherapistData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/therapists/${therapistId}`);
        setTherapistData(response.data);
      } catch (error) {
        console.error('Error fetching therapist data:', error);
      }
    };

    fetchTherapistData();
  }, [therapistId]);

  return (
    <div>
      {therapistData ? (
        <ShowProfileTherapistBU therapistData={therapistData} onBack={onBack} />
      ) : (
        <p>Loading profile...</p>
      )}
    </div>
  );
};

export default ScheduleTherapistPage;
