import React from 'react';

const TherapistRegistrationStep1 = ({ formData, handleChange, handleFileChange }) => {
  return (
    <div className="registration-step">
      <h2>Personal Information</h2>
      <input
        type="text"
        name="name"
        value={formData.name}
        onChange={handleChange}
        placeholder="Name"
        required
      />
      <input
        type="text"
        name="address"
        value={formData.address}
        onChange={handleChange}
        placeholder="Address"
        required
      />
      <input
        type="date"
        name="birthDate"
        value={formData.birthDate}
        onChange={handleChange}
        placeholder="Birth Date"
        required
      />
      <input
        type="tel"
        name="phone"
        value={formData.phone}
        onChange={handleChange}
        placeholder="Phone"
        required
      />
      <input
        type="text"
        name="qualification"
        value={formData.qualification}
        onChange={handleChange}
        placeholder="Qualification"
        required
      />
      <input
        type="text"
        name="experience"
        value={formData.experience}
        onChange={handleChange}
        placeholder="Experience"
        required
      />
      <input
        type="file"
        name="profilePhoto"
        accept="image/*"
        onChange={handleFileChange}
        required
      />
      {formData.profilePhoto && (
        <img
          src={URL.createObjectURL(formData.profilePhoto)}
          alt="Profile Preview"
          style={{ width: '100px', height: '100px' }}
        />
      )}
    </div>
  );
};

export default TherapistRegistrationStep1;
