import React from 'react';
import { useNavigate } from 'react-router-dom';

const Logoff = () => {
  const navigate = useNavigate();

  const handleLogoff = () => {
    // Realizar ações de logoff, como limpar o estado de autenticação
    navigate('/login');
  };

  return (
    <div onClick={handleLogoff} style={{ cursor: 'pointer' }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style={{ fill: 'rgba(0, 0, 0, 1)' }}>
        <path d="M16 13v-2H7V8l-5 4 5 4v-3z"></path>
        <path d="M20 3h-9c-1.103 0-2 .897-2 2v4h2V5h9v14h-9v-4H9v4c0 1.103.897 2 2 2h9c1.103 0 2-.897 2-2V5c0-1.103-.897-2-2-2z"></path>
      </svg>
    </div>
  );
};

export default Logoff;
