// src/components/Zoom/ZoomCallback.jsx
import React, { useEffect, useState } from 'react';
import axios from 'axios';

const ZoomCallback = () => {
  const [meetingUrl, setMeetingUrl] = useState('');

  useEffect(() => {
    const fetchAccessToken = async (code) => {
      try {
        const response = await axios.post('https://zoom.us/oauth/token', null, {
          params: {
            grant_type: 'authorization_code',
            code,
            redirect_uri: `${window.location.origin}/zoom/callback`,
          },
          headers: {
            Authorization: `Basic ${btoa(`${process.env.REACT_APP_ZOOM_CLIENT_ID}:${process.env.REACT_APP_ZOOM_CLIENT_SECRET}`)}`,
          },
        });

        const accessToken = response.data.access_token;
        createMeeting(accessToken);
      } catch (error) {
        console.error('Error fetching access token:', error);
      }
    };

    const createMeeting = async (accessToken) => {
      try {
        const response = await axios.post('https://api.zoom.us/v2/users/me/meetings', {
          topic: 'Therapy Session',
          type: 1,  // instant meeting
          settings: {
            host_video: true,
            participant_video: true,
          },
        }, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        setMeetingUrl(response.data.join_url);
      } catch (error) {
        console.error('Error creating Zoom meeting:', error);
      }
    };

    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    if (code) {
      fetchAccessToken(code);
    }
  }, []);

  return (
    <div>
      {meetingUrl ? (
        <div>
          <p>Meeting created successfully! Join here:</p>
          <a href={meetingUrl} target="_blank" rel="noopener noreferrer">{meetingUrl}</a>
        </div>
      ) : (
        <p>Creating meeting...</p>
      )}
    </div>
  );
};

export default ZoomCallback;
