import React, { useState } from 'react';
import './TherapistCard.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import ScheduleTherapistPage from '../../components/schedule/ScheduleTherapistPage';

const TherapistCard = ({ id, name, photo, expertise, description, price, rating, sessions, languages }) => {
  const [showSchedulePage, setShowSchedulePage] = useState(false);

  const expertiseArray = Array.isArray(expertise) && expertise.length > 0 ? expertise[0].split(',') : [];

  const handleScheduleClick = () => {
    setShowSchedulePage(true);
  };

  return (
    <>
      {showSchedulePage ? (
        <ScheduleTherapistPage therapistId={id} />
      ) : (
        <div className="therapist-card">
          <img src={`${process.env.REACT_APP_API_BASE_URL}/${photo}`} alt={name} className="therapist-photo" />
          <div className="therapist-info">
            <h3>{name}</h3>
            <div className="expertise-tags">
              {expertiseArray.map((item, index) => (
                <span key={index} className="badge custom-badge">{item.trim()}</span>
              ))}
            </div>
            <div className="languages">
              {languages.map((language, index) => (
                <div key={index} className="language">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chat-square-dots-fill language-icon" viewBox="0 0 16 16">
                    <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2h-2.5a1 1 0 0 0-.8.4l-1.9 2.533a1 1 0 0 1-1.6 0L5.3 12.4a1 1 0 0 0-.8-.4H2a2 2 0 0 1-2-2zm5 4a1 1 0 1 0-2 0 1 1 0 0 0 2 0m4 0a1 1 0 1 0-2 0 1 1 0 0 0 2 0m3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2"/>
                  </svg>
                  {language}
                </div>
              ))}
            </div>
            <div className="rating">{rating} ★</div>
            <div className="price">{price}</div>
          </div>
          <button type="button" className="custom-schedule-btn" onClick={handleScheduleClick}>Schedule</button>
        </div>
      )}
    </>
  );
};

export default TherapistCard;
