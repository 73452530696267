import React, { useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import './showProfileTherapist.css';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { isToday, parseISO } from 'date-fns';
import { useAuth } from '../../hooks/useAuth';

const ShowProfileTherapistBU = ({ therapistData }) => {
  const [showMore, setShowMore] = useState(false);
  const [view, setView] = useState('calendar');
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedHour, setSelectedHour] = useState(null);
  const { user } = useAuth(); 
  const navigate = useNavigate();

  if (!therapistData) {
    return <div>Loading profile...</div>;
  }

  const profilePhotoUrl = `${process.env.REACT_APP_API_BASE_URL}/${therapistData.profilePhoto}`;
  const videoUrl = `${process.env.REACT_APP_API_BASE_URL}/${therapistData.video}`;

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setView('calendar');
    setSelectedDate(null);
    setSelectedHour(null);
  };

  const toggleView = () => {
    setView(view === 'calendar' ? 'hours' : 'calendar');
  };

  // const onDateChange = (date) => {
  //   setSelectedDate(date);
  // };

  const scheduleAppointment = async () => {
    const payload = {
      date: selectedDate.toISOString(),
      hour: selectedHour,
      therapistId: therapistData._id,
      userId: user.uid
    };

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/new-schedule`, payload);
      Swal.fire('Success', 'You have requested an appointment, please wait for confirmation from the professional.', 'success')
        .then(() => closeModal());
    } catch (error) {
      console.error('Error creating schedule:', error);
      Swal.fire('Error', 'Error creating schedule', 'error')
        .then(() => closeModal());
    }
  };

  const renderHours = () => {
    const hours = [];
    for (let i = 8; i <= 17; i++) {
      hours.push(`${i < 10 ? '0' : ''}${i}:00 ${i < 12 ? 'AM' : 'PM'}`);
    }
    return (
      <div className="hours-view">
        {hours.map((hour, index) => (
          <div
            key={index}
            className={`hour-slot ${selectedHour === hour ? 'selected' : ''}`}
            onClick={() => setSelectedHour(hour)}
          >
            {hour}
          </div>
        ))}
        <div className="modal-footer">
          <button className="modal-back-btn" onClick={toggleView}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style={{ fill: 'rgba(0, 0, 0, 1);' }}><path d="M12.707 17.293 8.414 13H18v-2H8.414l4.293-4.293-1.414-1.414L4.586 12l6.707 6.707z"></path></svg>
          </button>
          <button
            className="modal-schedule-btn"
            onClick={scheduleAppointment}
            disabled={!selectedHour}
          >
            Request Appointment
          </button>
        </div>
      </div>
    );
  };

  const handleBackClick = () => {
    navigate('/apei');
  };

  const minDate = new Date();
  minDate.setHours(0, 0, 0, 0); 

  const onDateChange = (date) => {
    if (date < minDate && !isToday(date)) {
      Swal.fire('Error', 'You cannot select a past date.', 'error');
      return;
    }
    setSelectedDate(date);
  };

  return (
    <div className="profile-container">
      <div className="profile-header">
        <img src={profilePhotoUrl} alt="Profile" className="profile-photo-top" />
      </div>
      <div className="profile-content">
        <div className="profile-header-content">
          <div className="profile-info">
            <h2 className="profile-name">{therapistData.name}</h2>
            <div className="profile-expertise">
              {therapistData.areasOfExpertise.map((area, index) => (
                <span key={index} className="expertise-tag">{area}</span>
              ))}
            </div>
            <p className="profile-description">{therapistData.description}</p>
            <button className="profile-button" onClick={openModal}>Schedule</button>
          </div>
          {therapistData.video && (
            <video controls className="profile-video">
              <source src={videoUrl} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          )}
          <div className="profile-details">
            {showMore ? (
              <>
                <p className="detail-item"><strong>Qualification:</strong> {therapistData.qualification}</p>
                <p className="detail-item"><strong>Experience:</strong> {therapistData.experience}</p>
                <p className="detail-item"><strong>Consultation Type:</strong> {therapistData.consultationType}</p>
                <p className="detail-item"><strong>Specialties:</strong> {therapistData.specialties}</p>
                <p className="detail-item"><strong>Contact:</strong> {therapistData.contact}</p>
                <p className="detail-item"><strong>Email:</strong> {therapistData.email}</p>
                <p className="detail-item"><strong>Social Media:</strong> {therapistData.socialMedia}</p>
                {therapistData.website && (
                  <p className="detail-item"><strong>Website:</strong> <a href={therapistData.website} target="_blank" rel="noopener noreferrer">{therapistData.website}</a></p>
                )}
                <p className="detail-item"><strong>Consultation Fee:</strong> ${therapistData.consultationFee}</p>
                <button className="show-less-button" onClick={toggleShowMore}>
                  Show Less
                </button>
              </>
            ) : (
              <button className="show-more-button" onClick={toggleShowMore}>
                Show More
              </button>
            )}
          </div>
        </div>
      </div>
      {modalIsOpen && (
        <div className="modal-overlay" onClick={closeModal}>
          <div className="modal" onClick={(e) => e.stopPropagation()}>
            {view === 'calendar' ? (
              <div className="calendar-container">
                <Calendar onChange={onDateChange} />
                <button
                  className="toggle-view-btn"
                  onClick={toggleView}
                  disabled={!selectedDate}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24"><path d="m11.293 17.293 1.414 1.414L19.414 12l-6.707-6.707-1.414 1.414L15.586 11H6v2h9.586z"></path></svg>
                </button>
              </div>
            ) : (
              renderHours()
            )}
          </div>
        </div>
      )}
      <div className="back-btn">
        <button className="see-all-therapists-btn" onClick={handleBackClick}>
          See All Therapists
        </button>
      </div>
    </div>
  );
};

export default ShowProfileTherapistBU;
