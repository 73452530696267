import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import Notifications from '../components/Notifications/Notifications'; 
import ManagementTherapist from './management/ManagementTherapists.jsx'; 
import Logoff from '../components/logoff/Logoff.jsx';

const ProtectedRoute = ({ children }) => {
  const { user, loading } = useAuth();

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!user) {
    return <Navigate to="/login" />;
  }

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', padding: '10px' }}>
        <ManagementTherapist />
        <Notifications />
        <Logoff />
      </div>
      {children}
    </>
  );
};

export default ProtectedRoute;
