import React from 'react';

const TherapistRegistrationStep3 = ({ formData, handleChange }) => {
  return (
    <div className="registration-step">
      <h2 className='specialh2'>Consultation Type</h2>
      <div className="radio-container">
        <input
          type="radio"
          name="consultationType"
          value="online"
          checked={formData.consultationType === 'online'}
          onChange={handleChange}
        />
        <label className="radio-label">Online</label>
      </div>
      <div className="radio-container">
        <input
          type="radio"
          name="consultationType"
          value="online_presential"
          checked={formData.consultationType === 'online_presential'}
          onChange={handleChange}
        />
        <label className="radio-label">Online and Presential</label>
      </div>
      <div className="radio-container">
        <input
          type="radio"
          name="consultationType"
          value="presential"
          checked={formData.consultationType === 'presential'}
          onChange={handleChange}
        />
        <label className="radio-label">Presential</label>
      </div>
    </div>
  );
};

export default TherapistRegistrationStep3;
