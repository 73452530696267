import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Apei = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Define um timer para redirecionar após 2 segundos (2000 ms)
    const timer = setTimeout(() => {
      navigate('/patient-home');
    }, 2000);

    // Limpa o timer caso o componente seja desmontado antes do redirecionamento
    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <div className="loading">
      Loading...
    </div>
  );
};

export default Apei;
