import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from '../../hooks/useAuth';
import './ManagementTherapist.css';

const ManagementTherapist = () => {
  const { user } = useAuth();
  const [appointments, setAppointments] = useState([]);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const fetchAppointments = async () => {
      if (user) {
        try {
          const userId = user.userType === 'therapist' ? user._id : user.uid;
          const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/appointments/${user.userType}/${userId}`);
          console.log('Fetched appointments:', response.data);
          setAppointments(response.data);
        } catch (error) {
          console.error('Error fetching appointments:', error);
        }
      }
    };

    fetchAppointments();
  }, [user]);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  return (
    <div style={{ position: 'fixed', top: 20, right: 60, cursor: 'pointer', zIndex: 1000 }}>
      <div onClick={toggleModal} style={{ display: 'flex', alignItems: 'center' }}>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style={{ fill: '#5738a6' }}>
          <path d="M4 6h2v2H4zm0 5h2v2H4zm0 5h2v2H4zm16-8V6H8.023v2H18.8zM8 11h12v2H8zm0 5h12v2H8z"></path>
        </svg>
      </div>
      {showModal && (
        <div className="management-modal">
          <div className="modal-header">
            <h2>My Appointments</h2>
            <button onClick={toggleModal} className="close-button">X</button>
          </div>
          <div className="modal-content">
            <div className="column">
              <h3>Pending Confirmations</h3>
              {appointments.filter(app => app.status === 'pending').length > 0 ? (
                appointments.filter(app => app.status === 'pending').map(appointment => (
                  <div key={appointment._id} className="card">
                    <p><strong>Patient:</strong> <strong>{appointment.userName}</strong><br />
                       <strong>Therapist:</strong> <strong>{appointment.therapistName}</strong><br />
                       <strong>Date:</strong> <strong>{new Date(appointment.date).toLocaleDateString()}</strong><br />
                       <strong>Time:</strong> <strong>{appointment.hour}</strong></p>
                  </div>
                ))
              ) : (
                <p> </p>
              )}
            </div>
            <div className="column">
              <h3>Confirmed</h3>
              {appointments.filter(app => app.status === 'confirmed').length > 0 ? (
                appointments.filter(app => app.status === 'confirmed').map(appointment => (
                  <div key={appointment._id} className="card">
                    <p><strong>Patient:</strong> <strong>{appointment.userName}</strong><br />
                       <strong>Therapist:</strong> <strong>{appointment.therapistName}</strong><br />
                       <strong>Date:</strong> <strong>{new Date(appointment.date).toLocaleDateString()}</strong><br />
                       <strong>Time:</strong> <strong>{appointment.hour}</strong></p>
                  </div>
                ))
              ) 
              : (
                <p> </p>
              )
              }
            </div>
            <div className="column">
              <h3>Canceled</h3>
              {appointments.filter(app => app.status === 'canceled').length > 0 ? (
                appointments.filter(app => app.status === 'canceled').map(appointment => (
                  <div key={appointment._id} className="card">
                    <p><strong>Patient:</strong> <strong>{appointment.userName}</strong><br />
                       <strong>Therapist:</strong> <strong>{appointment.therapistName}</strong><br />
                       <strong>Date:</strong> <strong>{new Date(appointment.date).toLocaleDateString()}</strong><br />
                       <strong>Time:</strong> <strong>{appointment.hour}</strong></p>
                  </div>
                ))
              ) : (
                <p> </p>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ManagementTherapist;


// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { useAuth } from '../../hooks/useAuth';
// import './ManagementTherapist.css';

// const ManagementTherapist = () => {
//   const { user } = useAuth();
//   const [requests, setRequests] = useState([]);
//   const [confirmed, setConfirmed] = useState([]);
//   const [cancelled, setCancelled] = useState([]);
//   const [showModal, setShowModal] = useState(false);
//   const [expandedCard, setExpandedCard] = useState(null);

//   useEffect(() => {
//     const fetchRequests = async () => {
//       if (user) {
//         try {
//           const endpoint =
//             user.userType === 'therapist'
//               ? `${process.env.REACT_APP_API_BASE_URL}/api/appointments/therapist/${user._id}`
//               : `${process.env.REACT_APP_API_BASE_URL}/api/appointments/patient/${user.uid}`;

//           console.log('Fetching appointments for:', user.userType === 'therapist' ? 'therapist' : 'patient', 'ID:', user.userType === 'therapist' ? user._id : user.uid);
//           const response = await axios.get(endpoint);
//           console.log('Fetched appointments:', response.data);
//           setRequests(response.data.filter(request => request.status === 'pending'));
//           setConfirmed(response.data.filter(request => request.status === 'confirmed'));
//           setCancelled(response.data.filter(request => request.status === 'cancelled'));
//         } catch (error) {
//           console.error('Error fetching requests:', error);
//         }
//       }
//     };

//     fetchRequests();
//   }, [user]);

//   const handleAccept = async (appointmentId) => {
//     try {
//       await axios.put(`${process.env.REACT_APP_API_BASE_URL}/api/appointments/${appointmentId}/confirm`);
//       const updatedRequest = requests.find(request => request._id === appointmentId);
//       setRequests(requests.filter(request => request._id !== appointmentId));
//       setConfirmed([...confirmed, { ...updatedRequest, status: 'confirmed' }]);
//     } catch (error) {
//       console.error('Error accepting request:', error);
//     }
//   };

//   const handleReject = async (appointmentId) => {
//     try {
//       await axios.put(`${process.env.REACT_APP_API_BASE_URL}/api/appointments/${appointmentId}/reject`);
//       setRequests(requests.filter(request => request._id !== appointmentId));
//     } catch (error) {
//       console.error('Error rejecting request:', error);
//     }
//   };

//   const handleCancel = async (appointmentId) => {
//     try {
//       await axios.put(`${process.env.REACT_APP_API_BASE_URL}/api/appointments/${appointmentId}/cancel`);
//       const updatedRequest = confirmed.find(request => request._id === appointmentId);
//       setConfirmed(confirmed.filter(request => request._id !== appointmentId));
//       setCancelled([...cancelled, { ...updatedRequest, status: 'cancelled' }]);
//     } catch (error) {
//       console.error('Error cancelling request:', error);
//     }
//   };

//   const toggleModal = () => {
//     setShowModal(!showModal);
//   };

//   const toggleExpand = (appointmentId) => {
//     setExpandedCard(expandedCard === appointmentId ? null : appointmentId);
//   };

//   return (
//     <div style={{ position: 'fixed', top: 20, right: 60, cursor: 'pointer', zIndex: 1000 }}>
//       <div onClick={toggleModal} style={{ display: 'flex', alignItems: 'center' }}>
//         <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style={{ fill: '#5738a6' }}>
//           <path d="M4 6h2v2H4zm0 5h2v2H4zm0 5h2v2H4zm16-8V6H8.023v2H18.8zM8 11h12v2H8zm0 5h12v2H8z"></path>
//         </svg>
//       </div>
//       {showModal && (
//         <div className="management-modal">
//           <div className="modal-header">
//             <h2>My Appointments</h2>
//             <button onClick={toggleModal} className="close-button">X</button>
//           </div>
//           <div className="modal-content">
//             <div className="column">
//               <h3>Pending Confirmations</h3>
//               {requests.length > 0 ? (
//                 requests.map(request => (
//                   <div key={request._id} className={`card ${expandedCard === request._id ? 'expanded' : ''}`} onClick={() => toggleExpand(request._id)}>
//                     <p>{user.userType === 'therapist' ? `Patient: ${request.userId}` : `Therapist: ${request.therapistId}`}</p>
//                     <p>Date: {new Date(request.date).toLocaleString()}</p>
//                     {expandedCard === request._id && (
//                       <div className="expanded-content">
//                         <p>Additional details here...</p>
//                         {user.userType === 'therapist' && (
//                           <>
//                             <button onClick={() => handleAccept(request._id)}>Accept</button>
//                             <button onClick={() => handleReject(request._id)}>Reject</button>
//                           </>
//                         )}
//                       </div>
//                     )}
//                   </div>
//                 ))
//               ) : (
//                 <p>No pending appointments</p>
//               )}
//             </div>
//             <div className="column">
//               <h3>Confirmed</h3>
//               {confirmed.length > 0 ? (
//                 confirmed.map(request => (
//                   <div key={request._id} className={`card ${expandedCard === request._id ? 'expanded' : ''}`} onClick={() => toggleExpand(request._id)}>
//                     <p>{user.userType === 'therapist' ? `Patient: ${request.userId}` : `Therapist: ${request.therapistId}`}</p>
//                     <p>Date: {new Date(request.date).toLocaleString()}</p>
//                     {expandedCard === request._id && (
//                       <div className="expanded-content">
//                         <p>Additional details here...</p>
//                         {user.userType === 'therapist' && (
//                           <button onClick={() => handleCancel(request._id)}>Cancel</button>
//                         )}
//                       </div>
//                     )}
//                   </div>
//                 ))
//               ) : (
//                 <p>No confirmed appointments</p>
//               )}
//             </div>
//             <div className="column">
//               <h3>Cancelled</h3>
//               {cancelled.length > 0 ? (
//                 cancelled.map(request => (
//                   <div key={request._id} className={`card ${expandedCard === request._id ? 'expanded' : ''}`} onClick={() => toggleExpand(request._id)}>
//                     <p>{user.userType === 'therapist' ? `Patient: ${request.userId}` : `Therapist: ${request.therapistId}`}</p>
//                     <p>Date: {new Date(request.date).toLocaleString()}</p>
//                     {expandedCard === request._id && (
//                       <div className="expanded-content">
//                         <p>Additional details here...</p>
//                       </div>
//                     )}
//                   </div>
//                 ))
//               ) : (
//                 <p>No cancelled appointments</p>
//               )}
//             </div>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default ManagementTherapist;
