import React, { useState } from 'react';

const TherapistRegistrationStep6 = ({ formData, handleChange, handleFileChange }) => {
  const [certificates, setCertificates] = useState(formData.certificates || []);

  const addCertificate = () => {
    const newCertificates = [...certificates, null];
    setCertificates(newCertificates);
    // Atualiza o formData com a lista de certificados
    handleChange({ target: { name: 'certificates', value: newCertificates } });
  };

  const handleCertificateChange = (e, index) => {
    const file = e.target.files[0];
    const newCertificates = certificates.map((cert, i) =>
      i === index ? file : cert
    );
    setCertificates(newCertificates);

    // Atualizar o formData para incluir o novo arquivo de certificado
    handleChange({ target: { name: 'certificates', value: newCertificates } });
  };

  return (
    <div className="registration-step">
      <h2>Certifications</h2>
      <div className="form-group">
        <label>Professional Registration Number</label>
        <input
          type="text"
          name="professionalRegistrationNumber"
          value={formData.professionalRegistrationNumber || ''}
          onChange={handleChange}
          placeholder="Enter your professional registration number"
        />
      </div>

      <div className="form-group">
        <label>Upload Certificates</label>
        {certificates.map((_, index) => (
          <div key={index}>
            <input
              type="file"
              name={`certificate-${index}`}
              onChange={(e) => handleCertificateChange(e, index)}
            />
          </div>
        ))}
        <button type="button" onClick={addCertificate}>Add Another Certificate</button>
      </div>
    </div>
  );
};

export default TherapistRegistrationStep6;
