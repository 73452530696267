import React from 'react';
import axios from 'axios';

const Test = () => {
    const handleTestApi = async () => {
        try {
          const response = await axios.post('http://localhost:3001/api/schedule', {
            userId: "5f8d0d55b54764421b7156d9",  // Exemplo de um ObjectId válido
            therapistId: "5f8d0d55b54764421b7156d9",  // Exemplo de outro ObjectId válido
            date: "2023-01-01",
            hour: "10:00"
          });
          console.log('Response:', response.data);
          alert('Appointment scheduled successfully!');
        } catch (error) {
          console.error('Error making API request:', error);
          alert('Failed to schedule appointment: ' + error.message);
        }
      };
  return (
    <div>
      <h1>Test API</h1>
      <button onClick={handleTestApi}>Test Schedule API</button>
    </div>
  );
};

export default Test;
