import React from 'react';

const TherapistRegistrationStep4 = ({ formData, handleChange, handleFileChange }) => {
  return (
    <div className="registration-step">
      <h2>Additional Information</h2>
      <textarea
        name="description"
        value={formData.description}
        onChange={handleChange}
        placeholder="Description"
        required
      />
      <input
        type="text"
        name="specialties"
        value={formData.specialties}
        onChange={handleChange}
        placeholder="Specialties"
        required
      />
      <input
        type="file"
        name="video"
        accept="video/*"
        onChange={handleFileChange}
        required
      />
      {formData.video && (
        <video width="320" height="240" controls>
          <source src={URL.createObjectURL(formData.video)} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      )}
      <input
        type="tel"
        name="contact"
        value={formData.contact}
        onChange={handleChange}
        placeholder="Contact"
        required
      />
      <input
        type="email"
        name="email"
        value={formData.email}
        onChange={handleChange}
        placeholder="Email"
        required
      />
      <input
        type="text"
        name="socialMedia"
        value={formData.socialMedia}
        onChange={handleChange}
        placeholder="Social Media"
        required
      />
      <input
        type="text"
        name="website"
        value={formData.website}
        onChange={handleChange}
        placeholder="Website"
      />
      <input
        type="number"
        name="consultationFee"
        value={formData.consultationFee}
        onChange={handleChange}
        placeholder="Consultation Fee"
        required
      />
    </div>
  );
};

export default TherapistRegistrationStep4;
