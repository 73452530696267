import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import './SearchMenu.css';

const SearchMenu = ({ filters, onSearchChange, onCategoryChange, onLanguageChange, onSpecialistTypeChange }) => {
  return (
    <div className="search-menu">
      <div className="search-bar">
        <i className="search-icon">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
            <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0"/>
          </svg>
        </i>
        <input 
          type="text" 
          className="filter-options" 
          placeholder="Procure por nome, especialidade, motivo..." 
          value={filters.search}
          onChange={onSearchChange}
        />
      </div>
      <div className="filter-options btn-group dropdown">
        <button type="button" className="btn btn-outline-secondary dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          Especialistas
        </button>
        <div className="dropdown-menu my-1">
          <a href="#" className="dropdown-item" onClick={() => onCategoryChange({ target: { value: '' } })}>Todos</a>
          {/* <a href="#" className="dropdown-item" onClick={() => onCategoryChange({ target: { value: 'Mais relevantes' } })}>Mais relevantes</a>
          <a href="#" className="dropdown-item" onClick={() => onCategoryChange({ target: { value: 'Melhor Avaliação' } })}>Melhor Avaliação</a>
          <a href="#" className="dropdown-item" onClick={() => onCategoryChange({ target: { value: 'Mais Atendimentos' } })}>Mais Atendimentos</a> */}
          <a href="#" className="dropdown-item" onClick={() => onCategoryChange({ target: { value: 'Terapias individuais para casais' } })}>Terapias individuais para casais</a>
          <a href="#" className="dropdown-item" onClick={() => onCategoryChange({ target: { value: 'Terapias em grupo para casais' } })}>Terapias em grupo para casais</a>
          <a href="#" className="dropdown-item" onClick={() => onCategoryChange({ target: { value: 'Terapias alternativas' } })}>Terapias alternativas</a>
          <a href="#" className="dropdown-item" onClick={() => onCategoryChange({ target: { value: 'Terapias para divórcios' } })}>Terapias para divórcios</a>
          <a href="#" className="dropdown-item" onClick={() => onCategoryChange({ target: { value: 'Terapias para viúvos' } })}>Terapias para viúvos</a>
          <a href="#" className="dropdown-item" onClick={() => onCategoryChange({ target: { value: 'Terapias para solteiros' } })}>Terapias para solteiros</a>
          <a href="#" className="dropdown-item" onClick={() => onCategoryChange({ target: { value: 'Terapias com sexólogas' } })}>Terapias com sexólogas</a>
        </div>
      </div>
      <div className="filter-options btn-group dropdown">
        <button type="button" className="btn btn-outline-secondary dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          Idioma
        </button>
        <div className="dropdown-menu my-1">
          <a href="#" className="dropdown-item" onClick={() => onLanguageChange({ target: { value: '' } })}>Todos</a>
          <a href="#" className="dropdown-item" onClick={() => onLanguageChange({ target: { value: 'Inglês' } })}>Inglês</a>
          <a href="#" className="dropdown-item" onClick={() => onLanguageChange({ target: { value: 'Francês' } })}>Francês</a>
          <a href="#" className="dropdown-item" onClick={() => onLanguageChange({ target: { value: 'Alemão' } })}>Alemão</a>
          <a href="#" className="dropdown-item" onClick={() => onLanguageChange({ target: { value: 'Italiano' } })}>Italiano</a>
          <a href="#" className="dropdown-item" onClick={() => onLanguageChange({ target: { value: 'Português' } })}>Português</a>
        </div>
      </div>
      <div className="filter-options btn-group dropdown">
        <button type="button" className="btn btn-outline-secondary dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          Tipo de Especialista
        </button>
        <div className="dropdown-menu my-1">
          <a href="#" className="dropdown-item" onClick={() => onSpecialistTypeChange({ target: { value: '' } })}>Todos</a>
          <a href="#" className="dropdown-item" onClick={() => onSpecialistTypeChange({ target: { value: 'Terapeuta' } })}>Terapeuta</a>
          <a href="#" className="dropdown-item" onClick={() => onSpecialistTypeChange({ target: { value: 'Coach' } })}>Coach</a>
          <a href="#" className="dropdown-item" onClick={() => onSpecialistTypeChange({ target: { value: 'Nutricionista' } })}>Nutricionista</a>
          <a href="#" className="dropdown-item" onClick={() => onSpecialistTypeChange({ target: { value: 'Psicanalista' } })}>Psicanalista</a>
          <a href="#" className="dropdown-item" onClick={() => onSpecialistTypeChange({ target: { value: 'Psicólogo' } })}>Psicólogo</a>
          <a href="#" className="dropdown-item" onClick={() => onSpecialistTypeChange({ target: { value: 'Psiquiatra' } })}>Psiquiatra</a>
        </div>
      </div>
    </div>
  );
};

export default SearchMenu;
