// src/components/Zoom/Zoom.jsx
import React from 'react';

const Zoom = () => {
  const handleLogin = () => {
    const clientId = process.env.REACT_APP_ZOOM_CLIENT_ID;
    const redirectUri = encodeURIComponent(`${window.location.origin}/zoom/callback`);
    const zoomAuthUrl = `https://zoom.us/oauth/authorize?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}`;
    window.location.href = zoomAuthUrl;
  };

  return (
    <div>
      <button onClick={handleLogin}>Login with Zoom</button>
    </div>
  );
};

export default Zoom;
