import React from 'react';
import { useNavigate } from 'react-router-dom';
import './styles.css';
import logo from '../img/logo.png';

const UserTypeSelection = () => {
  const navigate = useNavigate();

  const handleSelection = (type) => {
    navigate(`/sign-up/${type}`);
  };

  return (
    <div className="lphh">
    <div className="user-type-selection">
      <div className="logo-container">
          <a href={process.env.REACT_APP_HOME_URL}>
            <img src={logo} alt="Find B Therapy Logo" className="logo" />
          </a>
      </div>
      <h1>Join our community</h1>
      <div className="buttons">
        <button onClick={() => handleSelection('patient')}>I'm looking for therapists</button>
        <button onClick={() => handleSelection('therapist')}>I'm a therapist</button>
      </div>
      </div>
    </div>
  );
};

export default UserTypeSelection;
