import React, { useState } from 'react';
import './showProfileTherapist.css';

const ShowProfileTherapist = ({ therapistData }) => {
  const [showMore, setShowMore] = useState(false);

  if (!therapistData) {
    return <div>Loading profile...</div>;
  }

  const profilePhotoUrl = `${process.env.REACT_APP_API_BASE_URL}/${therapistData.profilePhoto}`;
  const videoUrl = `${process.env.REACT_APP_API_BASE_URL}/${therapistData.video}`;

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  return (
    <div className="profile-container">
      <div className="profile-header">
        <img src={profilePhotoUrl} alt="Profile" className="profile-photo-top" />
      </div>
      <div className="profile-content">
        <div className="profile-header-content">
          <div className="profile-info">
            <h2 className="profile-name">{therapistData.name}</h2>
            <div className="profile-expertise">
              {therapistData.areasOfExpertise.map((area, index) => (
                <span key={index} className="expertise-tag">{area}</span>
              ))}
            </div>
            <p className="profile-description">{therapistData.description}</p>
          </div>
          {therapistData.video && (
            <video controls className="profile-video">
              <source src={videoUrl} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          )}
          <div className="profile-details">
            {showMore ? (
              <>
                <p className="detail-item"><strong>Qualification:</strong> {therapistData.qualification}</p>
                <p className="detail-item"><strong>Experience:</strong> {therapistData.experience}</p>
                <p className="detail-item"><strong>Consultation Type:</strong> {therapistData.consultationType}</p>
                <p className="detail-item"><strong>Specialties:</strong> {therapistData.specialties}</p>
                <p className="detail-item"><strong>Contact:</strong> {therapistData.contact}</p>
                <p className="detail-item"><strong>Email:</strong> {therapistData.email}</p>
                <p className="detail-item"><strong>Social Media:</strong> {therapistData.socialMedia}</p>
                {therapistData.website && (
                  <p className="detail-item"><strong>Website:</strong> <a href={therapistData.website} target="_blank" rel="noopener noreferrer">{therapistData.website}</a></p>
                )}
                <p className="detail-item"><strong>Consultation Fee:</strong> ${therapistData.consultationFee}</p>
                <button className="show-less-button" onClick={toggleShowMore}>
                  Show Less
                </button>
              </>
            ) : (
              <button className="show-more-button" onClick={toggleShowMore}>
                Show More
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShowProfileTherapist;
