import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../../firebase';
import Swal from 'sweetalert2';
import axios from 'axios';
import { useAuth } from '../../hooks/useAuth';
import logo from '../../img/logo.png';

const SignUp = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [userType, setUserType] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { setUser } = useAuth();
  const { type } = useParams();

  useEffect(() => {
    if (type === 'patient' || type === 'therapist') {
      setUserType(type);
    } else {
      navigate('/sign-up');
    }
  }, [type, navigate]);

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validatePassword = (password) => {
    const passwordRegex = /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&]).{6,}$/;
    return passwordRegex.test(password);
  };

  const handleSignUp = async (e) => {
    e.preventDefault();
    if (!validateEmail(email)) {
      Swal.fire('Error', 'Invalid email format.', 'error');
      return;
    }
    if (!validatePassword(password)) {
      Swal.fire('Error', 'Password must be at least 6 characters long, include at least one uppercase letter, one number, and one special character.', 'error');
      return;
    }
    setLoading(true);
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/users`, { uid: user.uid, userType });
      setUser({ ...user, userType, registration: false });
      setLoading(false);
      Swal.fire('Success', 'Account created successfully.', 'success');

      // Redirecionar diretamente para a página correta
      if (userType === 'patient') {
        navigate(process.env.REACT_APP_LOGIN_REDIRECT_PATIENT);
      } else if (userType === 'therapist') {
        navigate(process.env.REACT_APP_LOGIN_REDIRECT_THERAPIST);
      }
    } catch (error) {
      setLoading(false);
      Swal.fire('Error', error.message, 'error');
    }
  };

  return (
    <div className="sign-up-page">
      {loading ? (
        <div className="loading">
          <p>Loading...</p>
        </div>
      ) : (
        <div className="lph">
          <div className="logo-container">
            <a href={process.env.REACT_APP_HOME_URL}>
              <img src={logo} alt="Find B Therapy Logo" className="logo" />
            </a>
          </div>
          <div className='lph-title'>
            <h1>Create your account</h1>
          </div>
          <form className="login" onSubmit={handleSignUp}>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email"
              className="login-input"
            />
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Password"
              className="login-input"
            />
            <button type="submit">Sign Up</button>
          </form>
          <div className='link-su'>
            <Link to="/login" className="login-link">Go to Login</Link>
          </div>
        </div>
      )}
    </div>
  );
};

export default SignUp;
